//  Libs
import React, { useContext, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Context
import { HeaderContext } from '../../../context/header';
import { FooterContext } from '../../../context/footer';

// Components
import Layout from 'components/layout';
import SEO from 'components/seo';

import { device } from 'components/device';
import BreadCrumb from 'components/breadCrumb';
import DescriptionPage from 'components/contactUs/descriptionPage';

//Images
import HeaderIntern from 'components/contactUs/headerIntern';

// Styles
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;

  em {
    font-style: normal;
  }

  @media ${device.laptop} {
    display: block;
  }
`;

const Wrapper = styled.div`
  grid-column: 2 / -2;
`;

const ContainerContent = styled.div`
  display: inline-grid;
  grid-column: 6 / 10;
  flex-direction: column;

  a {
    color: #000;
    cursor: pointer;
    :visited {
      color: #000;
    }
  }

  h2 {
    margin-bottom: 50px;
    font-weight: 400;
    font-size: 1.5rem;

    @media ${device.tablet} {
      font-size: 1.125rem;
      :first-child {
        font-size: 1.125rem;
      }
    }

    @media ${device.mobile} {
      margin-bottom: 0.85rem;
    }
  }

  p {
    padding-bottom: 4.375rem;
    width: 100%;
    font-size: 1rem;
    line-height: 1.625rem;
    :last-child {
      display: unset;
      a {
        margin-bottom: 70px;
      }
    }
    a {
      margin-bottom: 10px;
      display: inline-flex;
      justify-content: space-between;
      padding: 0.907rem 1.25rem;
      width: 100%;
      color: #231f20;
      border: 0.0625rem solid #c6c8cc;
      border-radius: 6.25rem;
      background: #fff;
      list-style: none;
      cursor: pointer;
    }
  }

  @media ${device.laptop} {
    padding: 0 20px;
    display: flex;
    p {
      width: 100%;
      font-size: 0.875rem;
      em {
        font-size: 0.875rem;
      }
    }
  }

  @media ${device.tablet} {
    li {
      font-size: 0.875rem;
    }
  }
`;

const BoxImagens = styled.div`
  position: relative;
  display: flex;
  z-index: -2;
  justify-content: center;
  height: 125px;

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.mobile} {
    margin-top: 0;
  }
`;

const First = styled.img`
  position: absolute;
  width: 53%;
  transform: translate(-64%, 48%);

  @media ${device.laptop} {
    width: 60%;
    transform: translate(-71%, -3%);
  }

  @media ${device.mobile} {
    width: 55%;
    transform: translate(-92%, 37%);
  }
`;

const Second = styled.img`
  position: absolute;
  width: 77%;
  transform: translate(40%, 14%) rotate(148deg);

  @media ${device.laptop} {
    width: 85%;
    transform: translate(30%, -3%) rotate(152deg);
  }

  @media ${device.tablet} {
    width: 80%;
    transform: translate(33%, -5%) rotate(148deg);
  }

  @media ${device.mobile} {
    width: 100%;
    transform: translate(24%, 3%) rotate(148deg);
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else {
    return flatten(findFragment.localizations);
  }
};

const ComplimentOrComplaint = ({ page }) => {
  const { setHasDefaultHeader, setCallCenterBackLink } = useContext(
    HeaderContext
  );

  const { setHasFooter, setContactUs } = useContext(FooterContext);

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setHasDefaultHeader(false);
      setCallCenterBackLink('/fale-conosco/');
      setHasFooter(false);
      setContactUs('fale-conosco');
    }
  }, []);

  return (
    <Layout>
      <HeaderIntern />
      <SEO dataSeo={page} />
      <GridContainer>
        <Wrapper>
          <BreadCrumb
            markdown={getMarkdown(page, 'cki9940zk00xn0a77n0yepfrq', true)}
            marginTop
          />
        </Wrapper>
        <ContainerContent>
          <DescriptionPage
            markdown={getMarkdown(page, 'cki99b90o01ko0973g14hhpis', true)}
            widthLarge
          />
        </ContainerContent>
      </GridContainer>
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "cki991xdc00ry0962wr0hk9po" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <ComplimentOrComplaint page={response.gcms.site.pages} />;
      }}
    />
  );
};
